const routes = {
  albums: '/users/:userId/albums',
  album: '/users/:userId/albums/:albumId',

  tags: '/users/:userId/tags',
  tag: '/users/:userId/tags/:tagId',

  people: '/users/:userId/people',
  person: '/users/:userId/people/:personId',

  container: '/users/:userId/containers/:containerId',
  projects: '/users/:userId/print-projects',

  userProfile: '/users/:userId'
};

export function useBuildRoute() {
  const buildRoute = ({route, params, query}) => {
    let r = buildRouteParams(route, params);

    if (params.shareToken) {
      r = `${r}/${params.shareToken}`;
    }

    if (query) {
      r = `${r}${buildQueryString(query)}`;
    }

    return r;
  }

  return {
    toAlbums() {
      return buildRoute({route: routes.albums, params: {userId: useSessionStore().currentUser.slug}})
    },
    toAlbum({album, userId = useSessionStore().currentUser.slug, shareToken, query = {}}) {
      return buildRoute({
        route: routes.album,
        params: {
          albumId: album.slug,
          userId,
          shareToken
        },
        query
      });
    },

    toTags() {
      return buildRoute({route: routes.tags, params: {userId: useSessionStore().currentUser.slug}})
    },
    toTag({tag, userId = useSessionStore().currentUser.slug, shareToken, query = {}}) {
      return buildRoute({
        route: routes.tag,
        params: {
          tagId: tag.slug,
          userId,
          shareToken
        },
        query
      });
    },


    toPeople() {
      return buildRoute({route: routes.people, params: {userId: useSessionStore().currentUser.slug}})
    },

    toPerson({person, userId = useSessionStore().currentUser.slug, shareToken, query = {}}) {
      return buildRoute({
        route: routes.person,
        params: {
          personId: person.slug ,
          userId,
          shareToken
        },
        query
      });
    },

    toContainer({container, userId = useSessionStore().currentUser.slug, shareToken}) {
      return buildRoute({
        route: routes.container,
        params: {
          containerId: container.id,
          userId,
          shareToken
        }
      });
    },

    toUserProfile({user}) {
      return buildRoute({
        route: routes.userProfile,
        params: {
          userId: user.slug || user.id
        }
      });
    },
    toProjects() {
      return buildRoute({route: routes.projects, params: {userId: useSessionStore().currentUser.slug}})
    },
  }
}
